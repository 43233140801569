html {
  font-size: 16px;
  box-sizing: border-box;
}

:root {
  --gray-100: hsl(0, 0%, 10%);
  --gray-200: hsl(0, 0%, 25%);
  --gray-300: hsl(0, 0%, 45%);
  --gray-400: hsl(0, 0%, 65%);
  --gray-500: hsl(0, 0%, 90%);

  --colored-gray-100: hsl(210, 10%, 10%);
  --colored-gray-200: hsl(210, 20%, 25%);
  --colored-gray-300: hsl(210, 30%, 45%);
  --colored-gray-400: hsl(210, 40%, 65%);
  --colored-gray-500: hsl(210, 50%, 90%);

  --main-100: hsl(210, 100%, 10%);
  --main-200: hsl(210, 100%, 25%);
  --main-300: hsl(210, 100%, 55%);
  --main-400: hsl(210, 100%, 70%);
  --main-500: hsl(210, 100%, 95%);

  --secondary-100: hsl(25, 100%, 10%);
  --secondary-200: hsl(25, 100%, 25%);
  --secondary-300: hsl(25, 100%, 50%);
  --secondary-400: hsl(25, 100%, 67%);
  --secondary-500: hsl(25, 100%, 95%);

  --black: black;
  --white: white;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  margin: 0 auto;
  font-family: 'Josefin Sans', 'Righteous', 'Arial', serif, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--colored-gray-500);
  color: var(--secondary-200);
  line-height: 1.3;
  box-sizing: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

section {
  margin: 4rem 0;
  padding: 1rem 0;
  box-shadow: 0 1rem 3rem rgba(0,0,0,.5);
}

section:first-of-type {
  margin: 0;
}

@media screen and (min-width: 112.5em) {
  html {
    font-size: 100%;
  }
}

@media screen and (max-width: 75em) {
  html {
    font-size: 56.25%;
  }

  section {
    padding: 2rem 0;
  }
}

@media screen and (max-width: 37.5em) {
  html {
    font-size: 50%;
  }
}